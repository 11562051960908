/*******************************
         Site Overrides
*******************************/
.ui.labels a.label,
a.ui.label {
  &:hover {
    border: @border;
  }

  &.active {
    border: @border;
    &:hover {
      border: @border;
    }
  }

  & > i.icon {
    color: @gray-400;
  }
}
