/*******************************
         Site Overrides
*******************************/
:root {
  --base-white: @base-white;

  --gray-25: @gray-25;
  --gray-50: @gray-50;
  --gray-100: @gray-100;
  --gray-200: @gray-200;
  --gray-300: @gray-300;
  --gray-400: @gray-400;
  --gray-500: @gray-500;
  --gray-600: @gray-600;
  --gray-700: @gray-700;
  --gray-800: @gray-800;
  --gray-900: @gray-900;
  --gray-950: @gray-950;

  --primary-25: @primary-25;
  --primary-50: @primary-50;
  --primary-100: @primary-100;
  --primary-200: @primary-200;
  --primary-300: @primary-300;
  --primary-400: @primary-400;
  --primary-500: @primary-500;
  --primary-600: @primary-600;
  --primary-700: @primary-700;
  --primary-800: @primary-800;
  --primary-900: @primary-900;
  --primary-950: @primary-950;

  --error-25: @error-25;
  --error-50: @error-50;
  --error-100: @error-100;
  --error-200: @error-200;
  --error-300: @error-300;
  --error-400: @error-400;
  --error-500: @error-500;
  --error-600: @error-600;
  --error-700: @error-700;
  --error-800: @error-800;
  --error-900: @error-900;
  --error-950: @error-950;

  --success-25: @success-25;
  --success-50: @success-50;
  --success-100: @success-100;
  --success-200: @success-200;
  --success-300: @success-300;
  --success-400: @success-400;
  --success-500: @success-500;
  --success-600: @success-600;
  --success-700: @success-700;
  --success-800: @success-800;
  --success-900: @success-900;
  --success-950: @success-950;

  --green-25: @green-25;
  --green-50: @green-50;
  --green-100: @green-100;
  --green-200: @green-200;
  --green-300: @green-300;
  --green-400: @green-400;
  --green-500: @green-500;
  --green-600: @green-600;
  --green-700: @green-700;
  --green-800: @green-800;
  --green-900: @green-900;
  --green-950: @green-950;

  --blue-25: @blue-25;
  --blue-50: @blue-50;
  --blue-100: @blue-100;
  --blue-200: @blue-200;
  --blue-300: @blue-300;
  --blue-400: @blue-400;
  --blue-500: @blue-500;
  --blue-600: @blue-600;
  --blue-700: @blue-700;
  --blue-800: @blue-800;
  --blue-900: @blue-900;
  --blue-950: @blue-950;

  --blue-dark-25: @blue-dark-25;
  --blue-dark-50: @blue-dark-50;
  --blue-dark-100: @blue-dark-100;
  --blue-dark-200: @blue-dark-200;
  --blue-dark-300: @blue-dark-300;
  --blue-dark-400: @blue-dark-400;
  --blue-dark-500: @blue-dark-500;
  --blue-dark-600: @blue-dark-600;
  --blue-dark-700: @blue-dark-700;
  --blue-dark-800: @blue-dark-800;
  --blue-dark-900: @blue-dark-900;
  --blue-dark-950: @blue-dark-950;

  --indigo-25: @indigo-25;
  --indigo-50: @indigo-50;
  --indigo-100: @indigo-100;
  --indigo-200: @indigo-200;
  --indigo-300: @indigo-300;
  --indigo-400: @indigo-400;
  --indigo-500: @indigo-500;
  --indigo-600: @indigo-600;
  --indigo-700: @indigo-700;
  --indigo-800: @indigo-800;
  --indigo-900: @indigo-900;
  --indigo-950: @indigo-950;

  --black-40: @black-40;

  --primary-brand: @primary-brand;

  --orange-dark-600: @orange-dark-600;

  //
  // FomanticUI colors
  //
  --primary-color: @primary-600;
  --primary-background: @primary-600;

  --negative-text-color: @negativeTextColor;
  --negative-border-color: @negativeBorderColor;
  --negative-background-color: @negativeBackgroundColor;
  --selected-background: @selectedBackground;
  --selected-text-color: @selectedTextColor;
  --hovered-item-background: @hoveredItemBackground;
  --input-error-focus-box-shadow: @inputErrorFocusBoxShadow;
 
  //
  // Sizes
  //
  --abs-1px: @1px;
  --abs-2px: @2px;
  --abs-3px: @3px;
  --abs-4px: @4px;
  --abs-5px: @5px;
  --abs-6px: @6px;
  --abs-7px: @7px;
  --abs-8px: @8px;
  --abs-9px: @9px;
  --abs-10px: @10px;
  --abs-11px: @11px;
  --abs-12px: @12px;
  --abs-13px: @13px;
  --abs-14px: @14px;
  --abs-15px: @15px;
  --abs-16px: @16px;
  --abs-17px: @17px;
  --abs-18px: @18px;
  --abs-19px: @19px;
  --abs-20px: @20px;
  --abs-21px: @21px;
  --abs-22px: @22px;
  --abs-23px: @23px;
  --abs-24px: @24px;
  --abs-25px: @25px;
  --abs-26px: @26px;
  --abs-27px: @27px;
  --abs-28px: @28px;
  --abs-29px: @29px;
  --abs-30px: @30px;
  --abs-31px: @31px;
  --abs-32px: @32px;
  --abs-33px: @33px;
  --abs-34px: @34px;
  --abs-35px: @35px;
  --abs-36px: @36px;
  --abs-37px: @37px;
  --abs-38px: @38px;
  --abs-39px: @39px;
  --abs-40px: @40px;
  --abs-41px: @41px;
  --abs-42px: @42px;
  --abs-43px: @43px;
  --abs-44px: @44px;
  --abs-45px: @45px;
  --abs-46px: @46px;
  --abs-47px: @47px;
  --abs-48px: @48px;
  --abs-49px: @49px;
  --abs-50px: @50px;
  --abs-51px: @51px;
  --abs-52px: @52px;
  --abs-53px: @53px;
  --abs-54px: @54px;
  --abs-55px: @55px;
  --abs-56px: @56px;
  --abs-57px: @57px;
  --abs-58px: @58px;
  --abs-59px: @59px;
  --abs-60px: @60px;
  --abs-61px: @61px;
  --abs-62px: @62px;
  --abs-63px: @63px;
  --abs-64px: @64px;
}
