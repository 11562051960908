.application {
  min-width: 1200px;
  min-height: 600px;
  width: 100%;
  height: 100%;
  background: #ebebeb;
}
.application .router {
  width: 100%;
  height: 100%;
}
.app {
  padding: 8.2rem;
  width: 100%;
}
/deep/ .btn-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
}
