/*******************************
         Site Overrides
*******************************/
:root {
  --input-focus-box-shadow: @inputFocusBoxShadow;
}

.ui.form .field input:invalid {
  &:focus {
    box-shadow: @inputErrorFocusBoxShadow;
  }
}
