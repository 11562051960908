/*******************************
         Site Overrides
*******************************/
.ui.ui.ui.ui.button {
  font-size: @medium;
  line-height: @lineHeight;

  &:last-child {
    margin-right: 0px;
  }

  //
  // Button types overrides
  //
  &.primary {
    &:focus {
      box-shadow: 0px 0px 0px 4px @primary-100, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    
    &:disabled {
      opacity: 1 !important;
      background-color: @primary-200;
    }

    &.tertiary {
      color: @primaryTertiaryColor;

      &:hover {
        color: @primaryTertiaryColorHover;
        background-color: @primary-50;
      }

      &:focus {
        color: @primaryTertiaryColorFocus;
        background-color: @primary-50;
        box-shadow: none;
      }

      &:active {
        color: @primaryTertiaryColorActive;
        background-color: @primary-100;
      }

      &:disabled {
        background-color: @base-white;
        color: @gray-300;
      }
    }
  }

  &.basic {
    border: @basicBorder;
  }

  &.tertiary {
    border-radius: @borderRadius;
    padding: @verticalPadding @horizontalPadding !important;
    margin: 0 @horizontalMargin @verticalMargin 0 !important;

    &:disabled {
      opacity: 1 !important;
      color: @gray-300;
    }
  }

  // 
  // Button sizes
  //
  &.basic {
    // basic button has borders, so -2px on each side
    padding: unit((@verticalPadding - 0.1), rem) unit((@horizontalPadding - 0.1), rem);
  }

  &.small {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 0.8rem 1.4rem; 

    &.icon:not(.animated):not(.compact):not(.labeled) {
      // icon buttons have smaller paddings
      padding: 0.8rem;
    }

    &.basic {
      // basic button has borders, so -2px on each side
      padding: 0.7rem 1.3rem; 

      &.icon:not(.animated):not(.compact):not(.labeled) {
        padding: 0.7rem;
      }
    }

    &.tertiary {
      // tertiary in fomantic somewhy uses !important
      padding: 0.8rem 1.4rem !important; 
    }
  }

  &.large {
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 1rem 1.8rem; 

    &.basic {
      // basic button has borders, so -2px on each side
      padding: 0.9rem 1.7rem; 
    }

    &.tertiary {
      // tertiary in fomantic somewhy uses !important
      padding: 1rem 1.8rem !important; 
    }
  }

  &.big {
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 1.2rem 2rem; 

    &.basic {
      // basic button has borders, so -2px on each side
      padding: 1.1rem 1.9rem; 
    }

    &.tertiary {
      // tertiary in fomantic somewhy uses !important
      padding: 1.2rem 2rem !important; 
    }
  }

  &.huge {
    font-size: 1.8rem;
    line-height: 2.8rem;
    padding: 1.6rem 2.8rem; 

    &.basic {
      // basic button has borders, so -2px on each side
      padding: 1.5rem 2.7rem; 
    } 

    &.tertiary {
      // tertiary in fomantic somewhy uses !important
      padding: 1.6rem 2.8rem !important; 
    }
  }
}
