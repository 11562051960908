
body {
  transition: filter 0.3s
}

.carousel {
  outline: none
}

.container-max-width {
  max-width: 677px
  margin: 0 auto
}

i {
  &.icon.icon {
    margin: 0  
  }
}


.tik-tok-title {
  text-align: center
  padding: 64px 10px 53px
  margin: 0
}

.semibold {
  font-weight: 600
  color: #ededed
  font-size: 14px
}

.mobile-flex-center {
  display: contents
}


.radio-block-flex {
  display: flex
  flex-direction: column
  gap: 14px
}


.hoverable-block {
  .hoverable-block-item {
    transition: opacity .2s, border .2s
    border: 1px solid #e6e6e7

    &:hover:not(.active)
    &:hover:not(.active) .hoverable-block-item{
      border: 1px solid rgba(34, 36, 3, 0.22)

      .hoverable-block-item_text {
        color: rgba(0, 0, 0, 0.6)
      }
  
      .hoverable-block-item_image {
        opacity: 0.8
      }

      .buzz-radio label {
        &:before {
          border: 1px solid rgba(0,0,0,0.5)
        }
      } 
    }

    .hoverable-block-item_text {
      transition: color .2s  
      color: rgba(0,0,0,0.35)
    }
    .hoverable-block-item_image {
      opacity: 0.6
      transition: opacity .2s  
    }
    &.active,
    &.active .hoverable-block-item {
      border: 1px solid rgba(34, 36, 3, 0.35)

      .hoverable-block-item_text {
        color: rgba(0, 0, 0, 0.8)
      }
      .hoverable-block-item_image {
        opacity: 1
      }
    }
  }
}

.slider-wrapper {
  .slider-track {
    display:contents
  }
}

.pointer {
  cursor: pointer;
}

.error-message {
  height: 15px
  display: block
  color: #9f3a38 !important
  font-size: 12px
  visibility: hidden
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  line-height: 100%
  padding: 3px
  &.visible {
    visibility: visible
  }
  text-align: center
}

* {
  &::-webkit-scrollbar {
    display: block
    width: 4px !important
    height: 4px !important
  }
  &::-webkit-scrollbar-thumb {
    background: #CCCCCC !important
    border: 0px solid rgba(0, 0, 0, 0) !important
    background-clip: padding-box !important
    border-radius: 10px
  }
  &::-webkit-scrollbar-track {
    background: transparent !important
  }

  &::-webkit-scrollbar-button {
    display: none
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent
    display: none
  }
}

@media (max-width 1024px) {
  .container-max-width {
    padding: 0 20px 20px  
  }
  .mobile-flex-center {
    display: flex
    align-items: center  
    height: 100%
  }
  .slider-wrapper {
    height: max-content
    width: calc(100vw - calc(100vw - 100%))
    overflow: hidden
    padding: 0
    .slider-track {
      display: block !important
      padding: 0
      width: 9999px
      transition: transform 0.5s
    }
  }
}

@media (max-width 1300px) {
  .checkox_label {
      font-size: 12px
  }
}

@media (max-height: 786px) {
  .tik-tok-title {
    padding: 7.52vh 10px 6.23vh
    font-size: 17px
  }  
}
