/*******************************
        User Overrides
*******************************/
.ui.selection.dropdown {
  &.active .menu {
    -webkit-clip-path: inset(0px -5px -5px -5px);
    clip-path: inset(0px -5px -5px -5px);
  }
}

