.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  --el-color-primary: var(--primary-600);
  --el-loading-spinner-size: v-bind(c_size);
}
.loader .text {
  font-size: 1.4rem;
  color: var(--gray-700);
  text-align: center;
  margin-top: 1rem;
}
.loader .spinner {
  width: v-bind(c_size);
  height: v-bind(c_size);
}
.loader.inline {
  display: inline-block;
  position: inherit;
  transform: none;
}
.loader.inline .spinner {
  margin: 0;
}
