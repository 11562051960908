<template>
  <div class="loader" :class="{inline: inline}">
    <div v-loading="true" class="spinner" element-loading-background="transparent"/>
    <div v-if="$slots.default" class="text"><slot/></div>
  </div>
</template>

<style lang="stylus" scoped>
.loader {
  position: absolute
  left: 50%
  top: 50%
  transform: translateY(-50%) translateX(-50%)
  user-select: none
  
  display: flex
  flex-direction: column
  align-items: center

  .text {
    font-size: 1.4rem
    color: var(--gray-700)
    text-align: center
    margin-top: 1rem
  }

  .spinner {
    width: v-bind(c_size)
    height: v-bind(c_size)
  }

  &.inline {
    display: inline-block
    position: inherit
    transform: none
    .spinner {
      margin: 0
    }
  }
  
  --el-color-primary: var(--primary-600)
  --el-loading-spinner-size: v-bind(c_size)
}
</style>

<script>
export default {
  props: {
    size: {type: String, default: 'massive'},
    inline: {type: Boolean, default: false},
    mini: {type: Boolean, default: false},
    tiny: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
    large: {type: Boolean, default: false}
  },
  computed: {
    c_size() {
      if (this.mini) return '1.6rem'
      if (this.tiny) return '2.2rem'
      if (this.small) return '2.8rem'
      if (this.large) return '4.8rem'
      return '8.2rem'
      // 'mini': return '1.6rem'
      // 'tiny': return '2.2rem'
      // 'small': return '2.8rem' 
      // 'medium': return '3.2rem'  
      // 'large': return '4.8rem'
      // 'big': return '6.2rem'
      // 'huge': return '7.8rem'
      // 'massive': return '8.2rem'
    }
  }
}
</script>
