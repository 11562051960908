@import 'styles/mixins'

:root {
  --icon-stroke-width: 1.4
}

html {
  font-size: 62.5% !important
}

html 
body {
  width 100%
  height 100%
  margin 0
  padding 0
  overflow auto
}

h4 {
  font-size: 1.7rem
}

//
// convert control to 'error/red' state
// only as property, do not define a class
//
[error]:not([error="false"]) {
  filter: grayscale(100%) brightness(75%) sepia(100%) hue-rotate(-35deg) saturate(600%)
}

//
// disabled controls, icons & stuff
//
[disabled]:not([disabled="false"])
.disabled {
  opacity 0.3
  pointer-events none
  cursor default
}

//
// No user-select
//
.no-select {
  user-select: none
  -moz-user-select: none
  -khtml-user-select: none
  -webkit-user-select: none
  -o-user-select: none
  -webkit-app-region: no-drag;
}

//
// Do not wrap text
//
[nowrap]:not([nowrap="false"])
.nowrap {
  white-space: nowrap  
}

//
// selected elements
//
::selection{
  background: var(--primary-100) !important
}

::-moz-selection{
  background: var(--primary-100) !important
}

*:invalid {
  &::selection {
    background: var(--error-100) !important
    color: var(--negative-text-color) !important
  }
  &::-moz-selection{
    background: var(--error-100) !important
    color: var(--negative-text-color) !important
  }
}


[relative] {
  position: relative
}
 
//
// Flex row that centers content vertically
//
.row-vcenter {
  display flex
  flex-direction row
  align-items center
}

.row-hvcenter {
  @extend .row-vcenter
  justify-content center
}

.row-vbetween {
  @extend .row-vcenter
  justify-content space-between
}