<template>
  <Modal ref="modal" max_width="35em" min_width="20em" segment="red">
    <template v-slot:content>
      <div class="title">{{ title }}</div>
      <span class="error-text">
        {{ errtext }}
      </span>
    </template>
    <template v-slot:controls>
      <div></div>
      <button class="ui small basic button pointer" @click="onCopyError">
        Copy error
      </button>
      <button class="ui small basic button pointer" @click="onRestart">
        Restart
      </button>
      <div></div>
    </template>
  </Modal>
</template>

<style scoped lang="stylus">
.title {
  font-size: 17px
  font-weight: 800
  text-align: center
  color: #9F3A38
}

.error-text {
  color: rgba(0, 0, 0, 0.85)
  overflow: hidden
  text-align: center
}
</style>

<script>
import Modal from './modal'

export default {
  components: {
    Modal,
  },
  props: {
    error: {
      default: undefined,
      type: [String, Object]
    },
    title: {
      type: String,
      default: 'Error occurred'
    },
  },
  emits: ['copy'],
  computed: {
    errtext() {
      let result = undefined

      if (this.error.error.text) {
        result = this.error.error.text
      }
      else if (this.error.error instanceof Error) {
        result = this.error.error.message
      }

      return result ? result : 'Something went wrong. Please copy error message and contact support.'
    },

    errfull() {
      let formatError = (error) =>  {
        if (typeof error === 'string') {
          return error
        }
        
        if (error instanceof Error) {
          return error.stack
        }

        error = Object.assign({}, error)
        return $utils.format.json(error)
      }
      return formatError(this.error.error)
    }
  },

  mounted () {
    console.log('error')
    this.$refs.modal.open()
  },

  methods: {
    onCopyError() {
      $utils.copyText(this.errfull)
    },
 
    onRestart() {
      this.close()
    },

    close () {
      this.$refs.modal.close()
      this.$store.clearError()
    }
  }
}
</script>
