.title {
  font-size: 17px;
  font-weight: 800;
  text-align: center;
  color: #9f3a38;
}
.error-text {
  color: rgba(0,0,0,0.85);
  overflow: hidden;
  text-align: center;
}
