/*******************************
         Site Overrides
*******************************/
:root {
  --input-border-width: @borderWidth;
  --input-border-color: @borderColor;
  --input-error-color: @formErrorColor;
  --input-error-border-color: @formErrorBorder;
  --input-focus-border-color: @focusBorderColor;
  --input-hover-border-color: @hoverBorderColor;
  --input-transition: @transition;
  --input-horizontal-padding: @inputHorizontalPadding;
  --input-line-height: @inputLineHeight;
  --input-box-shadow: @boxShadow;
}

.ui.input > input:valid {
  &:hover:not(:focus) {
    border-color: @hoverBorderColor;
  }
}

.ui.input > input:invalid {
  &:focus {
    box-shadow: @inputErrorFocusBoxShadow;
  }
}

.ui[class*="left icon"].input > i.icon {
  left: @iconSpace;
}

.ui[class*="labeled"].input > .ui.label {
  background: white;
  font-weight: 400;
  color: @gray-600;
}

.ui.input > textarea {
  font-size: @fontSize;
    &:focus {
      box-shadow: @focusBoxShadow;
      border-color: @focusBorderColor;
    }
    &:hover {
      border-color: @hoverBorderColor;
    }
}