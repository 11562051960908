/*******************************
         Site Overrides
*******************************/
.ui.segment {
  &.padded {
    border-radius: @paddedSegmentBorderRadius;
  }
  &.raised {
    border-color: @raisedBorderColor;
  }
}
