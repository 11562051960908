/*******************************
         Site Overrides
*******************************/
:root {
  --checkbox-label-distance: @labelDistance;
}

.ui.checkbox label {
  &::after {
    box-sizing: border-box;
    font-weight: normal;
    line-height: @checkboxCheckSize;
  }
  &::before {
    box-sizing: border-box;
  }
}